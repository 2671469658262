import { Loader } from '@mantine/core';
import classNames from 'classnames';
import { CalendarBlankIcon } from 'common/assets';
import colors from 'common/styles/colors';
import { formatTimezone, getDateLocale } from 'common/utils/date';
import Divider from 'components/common/divider';
import { callMaybeFunction } from 'components/common/maybe';
import Button from 'components/elements/button';
import Checkbox from 'components/elements/checkbox';
import DatePickerInput from 'components/elements/date-picker-input';
import Text, { TextProps } from 'components/elements/text';
import { format } from 'date-fns';
import { useBindValue } from 'hooks/use-bind-value';
import { commonStyles } from 'modules/components/styles.css';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import structuralStyles from 'styles/layout.css';

interface DateWithTimezoneProps extends Omit<TextProps, 'children'> {
  date: Date | null | undefined;
  children: React.ReactNode | ((dateString: string | null) => React.ReactNode);
  format?: string;
}

export function DateWithTimezone(props: DateWithTimezoneProps) {
  const { date, format: dateFormat, children, ...textProps } = props;
  return (
    <Text textVariant="body2Regular" ta="left" {...textProps}>
      {callMaybeFunction(
        props.children,
        date
          ? format(date, dateFormat ?? 'dd MMM yyyy', {
              locale: getDateLocale(),
            })
          : null,
      )}
      {date != null && (
        <Text textColor="foregroundTertiary" span inherit>
          {` (${formatTimezone(new Date())})`}
        </Text>
      )}
    </Text>
  );
}

enum ActivationEnum {
  Now = 'now',
  Date = 'date',
}

interface Props {
  onChooseDate: (value: Date | null) => void;
  // controlled value
  date?: Date | null;
  // If the checkbox is switched to "Activate Later", this is the date that will be used.
  defaultDate?: Date | null;
  isLoading?: boolean;
  focus?: boolean;

  Bottom?: React.ReactNode;
}

const tomorrow = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 1,
);

const twoMonthLater = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate() + 90,
);

export default function ActivationDate(props: Props) {
  const { onChooseDate, date, defaultDate, isLoading, Bottom, focus } = props;
  const { t } = useTranslation();

  const activate = React.useMemo<ActivationEnum | undefined>(() => {
    if (typeof date === 'undefined') return undefined;
    if (date === null) return ActivationEnum.Now;
    return ActivationEnum.Date;
  }, [date]);

  return (
    <div
      className={classNames(
        structuralStyles.border({
          color: 'primary',
        }),
        structuralStyles.padding({ all: 16 }),
        structuralStyles.flexbox({
          direction: 'column',
          align: 'stretch',
          gap: 16,
        }),
        commonStyles.highlightTransition,
      )}
      style={{
        backgroundColor: focus ? colors.backgroundHighlight : colors.mainWhite,
        borderColor: focus ? colors.borderHighlight : undefined,
      }}
    >
      <div
        className={structuralStyles.flexbox({
          direction: 'row',
          align: 'start',
          gap: 12,
          fill: true,
        })}
      >
        {isLoading && <Loader size={24} />}
        <Text>{t('sim:activation_policy_description')}</Text>
      </div>

      <Divider orientation="horizontal" />

      <div
        className={structuralStyles.flexbox({
          direction: 'row',
          fill: true,
          gap: 8,
        })}
      >
        <Checkbox
          noMargin
          label={t('sim:activate_now')}
          checked={activate === ActivationEnum.Now}
          styles={{
            root: { display: 'flex', flex: 1 },
            body: { display: 'flex', flex: 1 },
            labelWrapper: { display: 'flex', flex: 1 },
          }}
          onChange={() => {
            onChooseDate(null);
          }}
          disabled={isLoading}
        />
      </div>

      <div
        className={structuralStyles.flexbox({
          direction: 'column',
          align: 'stretch',
          fill: true,
          gap: 10,
        })}
      >
        <div
          className={structuralStyles.flexbox({
            direction: 'row',
            fill: true,
            gap: 8,
          })}
        >
          <Checkbox
            noMargin
            label={t('sim:activate_later')}
            checked={activate === ActivationEnum.Date}
            styles={{
              root: { display: 'flex', flex: 1 },
              body: { display: 'flex', flex: 1 },
              labelWrapper: { display: 'flex', flex: 1 },
            }}
            onChange={() => {
              onChooseDate(defaultDate ? defaultDate : tomorrow);
            }}
            disabled={isLoading}
          />
        </div>
        {(typeof activate == 'undefined' ||
          activate === ActivationEnum.Date) && (
          <div
            className={classNames(
              structuralStyles.margin({ left: 32 }),
              structuralStyles.flexbox({
                direction: 'column',
                align: 'stretch',
                fill: true,
                gap: 8,
              }),
            )}
          >
            <Text textVariant="body2Regular">
              {t('sim:activate_later_desc')}
            </Text>
            <DatePickerInput
              disabled={activate !== ActivationEnum.Date || isLoading}
              noMargin
              dropdownType="modal"
              rightSectionWidth={60}
              value={date}
              defaultValue={defaultDate ? defaultDate : tomorrow}
              onChange={onChooseDate}
              modalProps={{ centered: true }}
              leftSection={<CalendarBlankIcon />}
              rightSection={
                <Text textVariant="body2Regular" textColor="foregroundTertiary">
                  {formatTimezone(new Date())}
                </Text>
              }
              minDate={tomorrow}
              maxDate={twoMonthLater}
            />
          </div>
        )}
      </div>
      <div />
      {Bottom}
    </div>
  );
}

export function TriggerActivationDate(props: Props) {
  const { t } = useTranslation();
  const [date, setDate] = React.useState<Date | null | undefined>(props.date);
  useBindValue(setDate, props.date);

  return (
    <ActivationDate
      date={date}
      onChooseDate={setDate}
      isLoading={props.isLoading}
      Bottom={
        <Button
          onClick={() => {
            props.onChooseDate?.(date ?? null);
          }}
          disabled={date === undefined}
        >
          {t('common:save')}
        </Button>
      }
    />
  );
}
